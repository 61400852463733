module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-asset-path/gatsby-browser.js'),
      options: {"plugins":[],"fileTypes":["js","webmanifest","json","map","css","png","txt"],"paths":["page-data","legal","travel-supplies","blog"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"World cruises agency","short_name":"World cruises agency","lang":"en-US","orientation":"any","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"./src/images/cruise/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"2fa4686e96039f27de696210bbde1856"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
